import './LoginForm.scss';
import React from 'react';
import { useLoginForm } from './useLoginForm';
import { Button } from 'components/ui-components';

export const LoginForm: React.FC = () => {
    const { formik, error } = useLoginForm();

    return (
        <form onSubmit={formik.handleSubmit} className="login-form">
            <h2>Se connecter</h2>
            <div className="inputs">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Adresse email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />

                <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Mot de passe"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                />
            </div>

            <Button text="Se connecter" type="submit" />

            {error && <p className="error-message">{error}</p>}
        </form>
    );
};
