import './Logo.scss';
import React from 'react';
import logo from 'assets/logos/logo.png';

export type LogoProps = {
    onClick?: () => void;
    isMobile?: boolean;
};

export const Logo: React.FC<LogoProps> = ({ onClick, isMobile }) => {
    return <img src={logo} className={'logo' + (isMobile ? ' mobile' : '')} onClick={() => onClick && onClick()} />;
};
