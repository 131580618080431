import './InputSearch.scss';
import React, { ChangeEventHandler } from 'react';
import { FaSearch } from 'react-icons/fa';

export type InputSearchProps = {
    placeholder?: string;
    onChange?: ChangeEventHandler;
};

export const InputSearch: React.FC<InputSearchProps> = ({ placeholder, onChange }) => {
    return (
        <div className="input-search">
            <input type="text" placeholder={placeholder} onChange={onChange} />
            <FaSearch />
        </div>
    );
};
