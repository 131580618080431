import './Users.scss';

import React from 'react';
import { useUsers } from './useUsers';
import { FaBoxArchive } from 'react-icons/fa6';
import { Button, InputSearch, Modal, Table } from 'components/ui-components';
import { DEFAULT_PAGINATION_SIZE } from 'components/ui-components/Table/Table';
import { IoArrowBack } from 'react-icons/io5';

export type UsersProps = {
    goBack: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Users: React.FC<UsersProps> = ({ goBack }) => {
    const { users, handleRevokeAccess, revokeUser, openRevokeModal, setOpenRevokeModal, userToRevoke, search, handleSetSearch } =
        useUsers();

    const ActionButtonsRenderer = (props: any) => {
        const {
            data: { firstname, lastname, email, id }
        } = props;
        return (
            <div className="actions">
                <button className="unstyled action" title="Révoquer l'accès">
                    <FaBoxArchive className="icon" onClick={() => handleRevokeAccess({ firstname, lastname, email, id })} />
                </button>
            </div>
        );
    };

    const columns = [
        { headerName: 'Nom', field: 'lastname' },
        { headerName: 'Prénom', field: 'firstname' },
        { headerName: 'Email', field: 'email' },
        { headerName: 'Établissement', valueGetter: (params: any) => params.data?.establishment?.name ?? '/' },
        { headerName: 'Actions', cellRenderer: ActionButtonsRenderer, maxWidth: 130, minWidth: 130 }
    ];

    return (
        <div className="users">
            <Button text="Retour" icon={<IoArrowBack />} onClick={() => goBack(false)} />
            <div className="title with-search">
                <h2>
                    Utilisateurs
                    <p>{users?.length ?? 0} utilisateurs actifs</p>
                </h2>
                <InputSearch placeholder="Recherche" onChange={handleSetSearch} />
            </div>
            {users && (
                <>
                    <Table
                        rows={users}
                        quickFilterText={search}
                        columns={columns}
                        pagination={true}
                        paginationPageSize={DEFAULT_PAGINATION_SIZE}
                    />
                </>
            )}
            <Modal
                title={`Révoquer l'accès de cet utilisateur`}
                open={!!openRevokeModal}
                onValidate={() => typeof userToRevoke?.id === 'number' && revokeUser(userToRevoke.id)}
                isValidateDestructive={true}
                setClose={() => setOpenRevokeModal(undefined)}
            >
                <p>
                    Voulez-vous vraiment révoquer l'accès de cet utilisateur{' '}
                    <b>
                        {userToRevoke?.firstname} {userToRevoke?.lastname} : {userToRevoke?.email}
                    </b>
                </p>
            </Modal>
        </div>
    );
};
