import './Loader.scss';
import React from 'react';
import { FidgetSpinner } from 'react-loader-spinner';

export const Loader: React.FC = () => {
    return (
        <div className="full-size-spinner">
            <FidgetSpinner
                visible={true}
                height="80"
                width="80"
                ariaLabel="fidget-spinner-loading"
                wrapperStyle={{}}
                wrapperClass="fidget-spinner-wrapper"
                backgroundColor="#ffffff"
                ballColors={['#fea4c9', '#fb821c', '#91c8ec']}
            />
        </div>
    );
};
