import { Patient } from 'components/Patients/Patients.types';
import { Button } from 'components/ui-components';
import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useWizard } from 'react-use-wizard';
import './AddPatient.scss';

export type AddPatientHeaderProps = {
    backAction: () => void;
    update: Patient | undefined;
};

export const AddPatientHeader: React.FC<AddPatientHeaderProps> = ({ backAction, update }) => {
    const { activeStep, stepCount } = useWizard();

    const titles = ['Informations générales', 'Dico Perso'];

    return (
        <>
            <div className="title-form">
                <Button text="Retour à l'accueil" icon={<IoArrowBack />} onClick={backAction} className="absolute" color="red" />
                <h2>{update ? `Modifier ${update.firstname} ${update.lastname}` : 'Ajouter une personne'}</h2>
                {stepCount !== 1 && (
                    <div className="form-stepper">
                        {Array.from({ length: stepCount }, (_value, index) => index + 1).map((step, index) => (
                            <div className={'stepper' + (activeStep + 1 === step ? ' active' : '')} key={index}>
                                <p className="stepper-number">{step}</p>
                                <p className="stepper-title">{titles[index]}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};
