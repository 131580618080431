import { Button } from 'components/ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useWizard } from 'react-use-wizard';

export type FormFooterProps = {
    createCta?: string;
};

export const FormFooter: React.FC<FormFooterProps> = ({ createCta }) => {
    const { previousStep, activeStep, stepCount } = useWizard();

    const computedText = activeStep + 1 === stepCount ? createCta ?? 'Ajouter' : 'Suivant';
    return (
        <div className="form-footer">
            {stepCount > 1 ? (
                <Button color="red" text="Étape précédente" disabled={activeStep === 0} onClick={() => previousStep()} />
            ) : (
                <div></div>
            )}
            <Button
                text={computedText}
                icon={computedText === 'Ajouter' || computedText === createCta ? <FaPlus /> : undefined}
                type="submit"
                form="form-patient"
            />
        </div>
    );
};
