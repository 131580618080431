import { getDetail } from 'api/patients';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Establishment, Patient } from '../Patients/Patients.types';

export const usePatient = (patientId: string | undefined) => {
    const [patient, setPatient] = useState<Patient | null>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [heightPatientDetails, setHeightPatientDetails] = useState<number | null>(null);
    const [heightPatienHeader, setheightPatienHeader] = useState<number | null>(null);
    const elementRefs = useRef<HTMLDivElement[]>([]);
    const patientDetailsRef = useRef<HTMLDivElement | null>(null);
    const patientHeaderRef = useRef<HTMLDivElement | null>(null);
    const [tabList, setTabList] = useState<any>();

    const handleScroll = (event: Event) => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const handleSetTabIndex = (index: number) => {
        setTabIndex(index);
        const scrollPosition = document.getElementsByClassName('react-tabs__tab-list')[0].scrollLeft;
        executeScroll(index, scrollPosition);
    };

    const executeScroll = (index: number, scrollPosition?: number) => {
        const targetTop = elementRefs.current[index].getBoundingClientRect().top;
        const offset = targetTop - 190;
        window.scrollBy({ top: offset, behavior: 'smooth' });
        if (scrollPosition) {
            setTimeout(() => {
                document.getElementsByClassName('react-tabs__tab-list')[0].scrollLeft = scrollPosition;
            }, 0);
        }
    };

    useEffect(() => {
        if (!patientDetailsRef?.current?.clientHeight) return;
        setHeightPatientDetails(patientDetailsRef.current.clientHeight);
    }, [patientDetailsRef?.current]);

    useEffect(() => {
        if (!patientHeaderRef?.current?.clientHeight) return;
        setheightPatienHeader(patientHeaderRef.current.clientHeight);
    }, [patientHeaderRef?.current]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!patientId) return;
        const parsedPatientId = Number(patientId);
        getDetail(parsedPatientId).then((data) => setPatient(data.data));
    }, [patientId]);

    const callTel = (tel: string | undefined) => {
        if (!tel) return;
        window.open(`tel:${tel}`);
    };

    return {
        patient,
        tabIndex,
        handleSetTabIndex,
        scrollPosition,
        executeScroll,
        elementRefs,
        patientDetailsRef,
        heightPatientDetails,
        patientHeaderRef,
        heightPatienHeader,
        callTel
    };
};
