import './Patients.scss';
import React from 'react';
import { usePatients } from './usePatients';

import { BsFillPencilFill } from 'react-icons/bs';
import { IoEye } from 'react-icons/io5';
import { DEFAULT_PAGINATION_SIZE, Table } from 'components/ui-components/Table/Table';
import { Establishment, Patient } from './Patients.types';
import { Button, InputSearch, Loader, Modal } from 'components/ui-components';
import { FaPlus } from 'react-icons/fa';
import { FaBoxArchive } from 'react-icons/fa6';

export type PatientsProps = {
    setEntityInfos: React.Dispatch<React.SetStateAction<Patient | Establishment | undefined>>;
    setShowCreatePatient: React.Dispatch<React.SetStateAction<boolean>>;
    setPatientToUpdate: React.Dispatch<React.SetStateAction<Patient | undefined>>;
};

export const Patients: React.FC<PatientsProps> = ({ setEntityInfos, setShowCreatePatient, setPatientToUpdate }) => {
    const {
        patients,
        search,
        handleSetSearch,
        handleDeletePatient,
        openDeleteModal,
        setOpenDeleteModal,
        navigateToPatientPreview,
        error,
        onRowSelected,
        patientToDelete,
        deletePatient
    } = usePatients({ setEntityInfos });

    const ActionButtonsRenderer = (props: any) => {
        const {
            data: { firstname, lastname, establishment, id }
        } = props;
        return (
            <div className="actions">
                {/* <button className="unstyled">
                    <FaQrcode className="icon" />
                </button> */}
                <button onClick={() => navigateToPatientPreview(id)} className="unstyled action" title="Voir sa page publique">
                    <IoEye className="icon" />
                </button>
                <button
                    className="unstyled action"
                    title="Modifier"
                    onClick={(e) => {
                        const patient = patients?.find((p) => p.id === id);
                        setPatientToUpdate(patient);
                        onRowSelected({
                            node: {
                                selected: true
                            },
                            data: patient
                        });
                    }}
                >
                    <BsFillPencilFill className="icon" />
                </button>
                <button className="unstyled action" title="Archiver">
                    <FaBoxArchive
                        className="icon"
                        onClick={() =>
                            handleDeletePatient({
                                firstname,
                                lastname,
                                establishment,
                                id
                            })
                        }
                    />
                </button>
            </div>
        );
    };

    const columns = [
        { headerName: 'Nom', field: 'lastname' },
        { headerName: 'Prénom', field: 'firstname' },
        { headerName: 'Établissement', valueGetter: (params: any) => params.data?.establishment?.name ?? '/' },
        { headerName: 'Personne à contacter', valueGetter: (params: any) => params.data?.emergency_contact_name ?? '/' },
        { headerName: 'Numéro urgence', valueGetter: (params: any) => params.data?.emergency_contact_number ?? '/' },
        { headerName: 'Actions', cellRenderer: ActionButtonsRenderer, maxWidth: 130, minWidth: 130 }
    ];

    return (
        <div className="patients">
            <div className="title with-search">
                <h2>
                    Personnes
                    <p>{patients?.length ?? 0} personnes enregistrées</p>
                </h2>
                <InputSearch placeholder="Recherche" onChange={handleSetSearch} />
                <Button icon={<FaPlus />} color={'blue'} text="Ajouter une personne" onClick={() => setShowCreatePatient(true)} />
            </div>
            {patients && (
                <>
                    <Table
                        rows={patients}
                        quickFilterText={search}
                        columns={columns}
                        pagination={true}
                        onRowSelected={onRowSelected}
                        paginationPageSize={DEFAULT_PAGINATION_SIZE}
                    />
                </>
            )}

            {!patients && !error && <Loader />}
            {!patients && error && <p className="error-message">{error}</p>}

            <Modal
                title={`Archiver une personne`}
                open={openDeleteModal}
                onValidate={() => deletePatient(patientToDelete)}
                isValidateDestructive={true}
                setClose={() => setOpenDeleteModal(false)}
            >
                <p>
                    Voulez-vous vraiment archiver la personne{' '}
                    <b>
                        {patientToDelete?.firstname} {patientToDelete?.lastname}
                    </b>
                </p>
            </Modal>
        </div>
    );
};
