import React, { useEffect, useState } from 'react';
import { del, getAll } from 'api/establishments';
import { useNavigate } from 'react-router-dom';
import { Establishment, Establishments } from 'components/Patients/Patients.types';

export const useEstablishments = () => {
    const [establishments, setEstablishments] = useState<Establishments | null>(null);
    const [search, setSearch] = useState<string>('');
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [establishmentToDelete, setEstablishmentToDelete] = useState<Partial<Establishment> | undefined>();
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();

    const handleSetSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const fetchAllEstablishments = () => {
        getAll()
            .then((data) => {
                const receivedEstablishments = (data.data as Establishments).reverse();
                setEstablishments(receivedEstablishments);
            })
            .catch((err) => {
                setError(err.response.data.message);
            });
    };

    const handleDeleteEstablishment = (establishment: Partial<Establishment>) => {
        setEstablishmentToDelete(establishment);
        setOpenDeleteModal(true);
    };

    const deleteEstablishment = (establishment: Partial<Establishment> | undefined) => {
        if (!establishment || !establishment.id) return;
        del(establishment.id).then((data) => {
            fetchAllEstablishments();
        });
    };

    const navigateToEstablishmentDetails = (id: number) => {
        navigate(`establishment/${id}`);
    };

    useEffect(() => {
        fetchAllEstablishments();
    }, []);

    return {
        establishments,
        search,
        handleSetSearch,
        handleDeleteEstablishment,
        navigateToEstablishmentDetails,
        openDeleteModal,
        deleteEstablishment,
        establishmentToDelete,
        setOpenDeleteModal,
        error
    };
};
