import { useState, useEffect, useRef } from 'react';
import Flow from '@flowjs/flow.js';
import { store } from 'features/index';
import { BASE_API_URL } from 'api/client';

export type UserFile = {
    id: number;
    view_link: string;
    mime_type: string;
    original_filename: string;
};

export const useFlowJs = () => {
    const [flow, setFlow] = useState<any>();
    const [isFlowInitialized, setIsFlowInitialized] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [document, setDocument] = useState<UserFile | undefined>();
    const [userDocuments, setUserDocuments] = useState();
    const [error, setError] = useState<string | undefined>();
    const flowDropRef = useRef(null);

    useEffect(() => {
        setFlow(
            new Flow({
                target: `${BASE_API_URL}/cdn/file/import`,
                query: { jwt: store.getState().authentication.jwt },
                singleFile: true
            })
        );
    }, []);

    useEffect(() => {
        if (!flow || !flow.support || !flowDropRef || !flowDropRef.current) return;

        flow.assignDrop(flowDropRef.current);
        flow.assignBrowse(flowDropRef.current, false, true, { accept: 'image/png, image/jpeg' });
        setIsFlowInitialized(true);
    }, [flow, flowDropRef]);

    useEffect(() => {
        if (!isFlowInitialized) return;

        flow.on('fileAdded', function (flowFile: any, event: any) {
            const acceptedMimeTypes = ['image/png', 'image/jpeg'];
            const maxSize = 5000000;
            if (acceptedMimeTypes.includes(flowFile.file.type) && flowFile.file.size < maxSize) {
                setError(undefined);

                setTimeout(() => {
                    flowFile.flowObj.upload();
                    setUploadLoading(true);
                }, 0);
            } else {
                setError("Format ou taille de l'image non reçevable");
            }
        });

        flow.on('error', function (e: any) {
            setError('Une erreur inconnue est survenue, veuillez réessayer plus tard...');
        });

        flow.on('fileSuccess', function (file: File, apiFile: any) {
            const { id, view_link, mime_type, original_filename } = JSON.parse(apiFile);
            setDocument({
                id,
                view_link,
                mime_type,
                original_filename
            });
            setUploadLoading(false);
        });
    }, [isFlowInitialized, flow]);

    return {
        flowDropRef,
        userDocuments,
        document,
        setDocument,
        error,
        uploadLoading,
        flow
    };
};
