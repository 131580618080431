import './Register.scss';

import React from 'react';
import { useRegister } from './useRegister';
import { useParams } from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';
import { Button } from 'components/ui-components';

export const Register: React.FC = () => {
    const { token, user, password, setPassword, callRegister, passwordIsValid, setPasswordIsValid, error } = useRegister();

    if (!token) return <></>;
    // if (!token || !user) return <></>;

    return (
        <div className="register">
            <p>
                Bonjour <b>{user?.firstname}</b>,<br />
                <br />
                Créez votre mot de passe personnel afin de pouvoir accéder à l’application Dico Perso.
                <br />
                Conservez le en lieu sur !
            </p>

            <div className="form">
                <div className="step">
                    <div className="form">
                        <div className="input">
                            <label htmlFor="email">Votre adresse email</label>

                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                disabled
                                placeholder="Votre adresse email"
                                value={user?.email}
                            />
                        </div>
                        <div className="input">
                            <label htmlFor="password">Mot de passe *</label>

                            <input
                                type="password"
                                id="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Mot de passe"
                                value={password}
                            />

                            <PasswordChecklist
                                rules={['minLength', 'specialChar', 'number']}
                                minLength={8}
                                value={password}
                                onChange={(isValid) => {
                                    setPasswordIsValid(isValid);
                                }}
                                messages={{
                                    minLength: 'Le mot de passe doit contenir au moins 8 caractères',
                                    specialChar: 'Le mot de passe doit contenir au moins un caractère spécial',
                                    number: 'Le mot de passe doit contenir au moins un chiffre'
                                }}
                            />
                        </div>
                    </div>
                    <Button text="Enregister votre mot de passe" disabled={!passwordIsValid} onClick={callRegister} />
                </div>
            </div>
            {error && <p className="form-error">{error}</p>}
        </div>
    );
};
