import './Button.scss';
import React, { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string;
    icon?: React.ReactNode;
    colored?: boolean;
    color?: 'yellow' | 'blue' | 'red' | 'lightyellow';
    isAnAction?: boolean;
    settings?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
    text,
    icon,
    color = 'blue',
    colored = true,
    isAnAction = false,
    className,
    settings,
    ...restProps
}) => {
    const defaultClassName = 'button' + (colored ? ' colored' : '') + (settings ? ' settings-style' : '');
    const mergedClassName = className ? `${defaultClassName} ${className}` : defaultClassName;

    return (
        <button data-color={color} className={mergedClassName} {...restProps}>
            {icon && <>{icon}</>}
            {!isAnAction && text && <span>{text}</span>}
        </button>
    );
};
