import { list, revokeAccess } from 'api/auth';
import { useEffect, useState } from 'react';

export type User = {
    email: string;
    firstname: string;
    fullname: string;
    id: number;
    lastname: string;
    online: boolean;
    role: string;
};

export const useUsers = () => {
    const [users, setUsers] = useState<User[] | null>(null);
    const [openRevokeModal, setOpenRevokeModal] = useState<number>();
    const [search, setSearch] = useState<string>('');
    const [userToRevoke, setUserToRevoke] = useState<Partial<User>>();

    const revokeUser = (id: number) => {
        revokeAccess(id).then((data) => {
            fetchUsers();
        });
    };

    const handleRevokeAccess = (user: Partial<User>) => {
        setUserToRevoke(user);
        setOpenRevokeModal(user.id);
    };

    const handleSetSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const fetchUsers = () => {
        list().then((data) => setUsers(data.data));
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return {
        users,
        handleRevokeAccess,
        revokeUser,
        openRevokeModal,
        setOpenRevokeModal,
        userToRevoke,
        search,
        handleSetSearch
    };
};
