import './Archives.scss';
import { Button, Modal } from 'components/ui-components';
import { useArchives } from './useArchives';
import { DEFAULT_PAGINATION_SIZE, Table } from 'components/ui-components/Table/Table';
import { Establishment, Patient } from 'components/Patients/Patients.types';

export type ArchivesType = {
    setEntityInfos?: any;
};

export const Archives: React.FC<ArchivesType> = ({ setEntityInfos }) => {
    const {
        selectedEntityType,
        handleUpdateSelectedEntity,
        formData,
        onRowSelected,
        handleRestoreEntity,
        restoreEntity,
        entityToRestore,
        openRestoreModal,
        setOpenRestoreModal
    } = useArchives({ setEntityInfos });

    return (
        <div className="archives">
            <div className="archives-choices">
                <Button
                    text="Personnes"
                    className={selectedEntityType === 'personnes' ? 'selected' : ''}
                    onClick={() => handleUpdateSelectedEntity('personnes')}
                />
                <Button
                    text="Établissements"
                    className={selectedEntityType === 'etablissements' ? 'selected' : ''}
                    onClick={() => handleUpdateSelectedEntity('etablissements')}
                />
            </div>

            <div className="title">
                <h2>Archives</h2>
            </div>

            {selectedEntityType && formData && (
                <Table
                    rows={formData.rows}
                    columns={formData.columns}
                    quickFilterText={''}
                    pagination={true}
                    onRowSelected={onRowSelected}
                    paginationPageSize={DEFAULT_PAGINATION_SIZE}
                />
            )}

            <Modal
                title={`Restaurer ${selectedEntityType === 'personnes' ? 'une personne' : 'un établissement'}`}
                open={openRestoreModal}
                onValidate={() => restoreEntity(entityToRestore)}
                isValidateDestructive={true}
                setClose={() => setOpenRestoreModal(false)}
            >
                <p>
                    Voulez-vous vraiment restaurer {selectedEntityType === 'personnes' ? 'la personne' : "l'établissement"}{' '}
                    <b>
                        {(entityToRestore as Patient)?.firstname} {(entityToRestore as Patient)?.lastname}{' '}
                        {(entityToRestore as Establishment)?.name} {(entityToRestore as Establishment)?.address}
                    </b>{' '}
                    ?
                </p>
            </Modal>
        </div>
    );
};
