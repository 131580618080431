import AuthentifiedLayout from 'layout/AuthentifiedLayout';
import UnAuthentifiedLayout from 'layout/UnAuthentifiedLayout';

import { Navigate } from 'react-router-dom';
import { UnAuthenfiedView } from '../views/unAuthentifiedView/UnAthentifiedView';
import { AuthentifiedView } from 'views/authentifiedView/AuthentifiedView';
import { PatientView } from 'views/patientView/PatientView';
import { Preview } from 'components/Patient/Preview/Preview';
import { RegisterView } from 'views/registerView/RegisterView';
import { DicoCard } from 'components/DicoCard/DicoCard';

export const routes = (isLogged: boolean | null | undefined) => {
    return [
        {
            path: 'patient/:id',
            element: <PatientView />
        },
        {
            path: 'card/:id',
            element: <DicoCard />
        },
        {
            path: 'patient/preview/:id',
            element: <Preview />
        },
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        {
            path: '/login',
            element: !isLogged ? <UnAuthentifiedLayout /> : <Navigate to="/dico-perso" />,
            children: [
                {
                    path: '',
                    element: <UnAuthenfiedView />
                }
            ]
        },
        {
            path: '/register/:token',
            element: <UnAuthentifiedLayout />,
            children: [
                {
                    path: '',
                    element: <RegisterView />
                }
            ]
        },
        {
            path: '/dico-perso',
            element: isLogged ? <AuthentifiedLayout /> : <Navigate to="/login" />,
            children: [
                {
                    path: '',
                    element: <AuthentifiedView />
                }
            ]
        }
    ];
};

export default routes;
