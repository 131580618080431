import axiosClient from 'api/client';
import reformulateAxiosClient from 'api/reformulateClient';

export const getBookletTabs = () => {
    return axiosClient.get('/booklettabs/list').then((response) => response);
};

export const bulkUpdateBookletTabs = (data: any) => {
    return axiosClient.post('/booklettabsconfiguration/bulk', data).then((response) => response);
};

export const reformulateContent = (prompt: string) => {
    return reformulateAxiosClient
        .post(
            '/request/generate',
            { prompt: prompt },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        .then((response) => response);
};
