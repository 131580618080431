import { Button } from 'components/ui-components';
import './AddPatient.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { IoArrowBack } from 'react-icons/io5';

export type AddPatientFooterProps = {
    createCta?: string;
};

export const AddPatientFooter: React.FC<AddPatientFooterProps> = ({ createCta }) => {
    const { previousStep, activeStep, stepCount } = useWizard();

    const computedText = activeStep + 1 === stepCount ? createCta ?? 'Ajouter' : 'Suivant';
    return (
        <div className="form-footer">
            <Button icon={<IoArrowBack />} text="Étape précédente" disabled={activeStep === 0} onClick={() => previousStep()} />
            <Button text={computedText} type="submit" form="form-patient-dico" />
        </div>
    );
};
