import { useWizard } from 'react-use-wizard';
import { FormStepDetail } from '../Form.types';
import { Button } from 'components/ui-components';
import { IoArrowBack } from 'react-icons/io5';

export type FormHeaderProps = {
    title: string;
    steps: FormStepDetail[];
    backAction: () => void;
    hasBackAction?: boolean;
};

export const FormHeader: React.FC<FormHeaderProps> = ({ title, steps, backAction, hasBackAction }) => {
    const { activeStep, stepCount } = useWizard();

    return (
        <>
            <div className="title-form">
                {hasBackAction && (
                    <Button text="Retour à l'accueil" color="red" icon={<IoArrowBack />} onClick={backAction} className="absolute" />
                )}
                <h2>{title}</h2>
                {stepCount !== 1 && (
                    <div className="form-stepper">
                        {Array.from({ length: stepCount }, (value, index) => index + 1).map((step, index) => (
                            <div className={'stepper' + (activeStep + 1 === step ? ' active' : '')} key={index}>
                                <p className="stepper-number">{step}</p>
                                <p className="stepper-title">{steps[index].title}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};
