import { getUserFromToken, register } from 'api/auth';
import { UserType, login as loginDispatch, logout } from 'features/auth';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export const useRegister = () => {
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [user, setUser] = useState<Partial<UserType>>();
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { token } = useParams();
    const navigate = useNavigate();

    const callRegister = () => {
        if (!password || !token) return;
        setError('');
        register({ password, token })
            .then((data) => {
                const { data: loginResponse } = data;
                dispatch(loginDispatch(loginResponse));
                navigate('/#/dico-perso');
            })
            .catch((_e) => {
                setError('Une erreur est survenue');
            });
    };

    useEffect(() => {
        dispatch(logout());
        if (!token) return;
        getUserFromToken(token)
            .then((data) => {
                setUser(data.data);
            })
            .catch((e) => {
                setError('Utilisateur introuvable...');
            });
    }, []);

    return {
        token,
        user,
        password,
        setPassword,
        callRegister,
        passwordIsValid,
        setPasswordIsValid,
        error
    };
};
