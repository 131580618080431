import { getIsLogged } from 'features/auth';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useHeaderVm = () => {
    const navigate = useNavigate();
    const handleLogoClick = () => navigate('/dico-perso');

    return {
        handleLogoClick
    };
};
