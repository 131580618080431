import React, { useEffect, useState } from 'react';
import { Establishment, Patient, Patients } from './Patients.types';
import { del, getAll } from 'api/patients';
import { useNavigate } from 'react-router-dom';

export type usePatientsProps = {
    setEntityInfos: React.Dispatch<React.SetStateAction<Patient | Establishment | undefined>>;
};

export const usePatients = ({ setEntityInfos }: usePatientsProps) => {
    const [patients, setPatients] = useState<Patients | null>(null);
    const [search, setSearch] = useState<string>('');
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [patientToDelete, setPatientToDelete] = useState<Partial<Patient> | undefined>();
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();

    const handleSetSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const fetchAllPatients = () => {
        getAll()
            .then((data) => {
                const receivedPatients = (data.data as Patients).reverse();
                setPatients(receivedPatients);
            })
            .catch((err) => {
                setError(err.response.data.message);
            });
    };

    const handleDeletePatient = (patient: Partial<Patient>) => {
        setPatientToDelete(patient);
        setOpenDeleteModal(true);
    };

    const deletePatient = (patient: Partial<Patient> | undefined) => {
        if (!patient || !patient.id) return;
        del(patient.id).then((data) => {
            fetchAllPatients();
        });
    };

    const navigateToPatientPreview = (id: number) => {
        navigate(`/patient/preview/${id}`);
    };

    useEffect(() => {
        fetchAllPatients();
    }, []);

    //Move to usePatients
    useEffect(() => {
        if (patients && patients.length > 0) setEntityInfos(patients[0]);
    }, [patients, setEntityInfos]);

    const onRowSelected = (event: any) => {
        if (!event.node.selected) return;
        setEntityInfos(event.data);
    };

    return {
        patients,
        search,
        handleSetSearch,
        handleDeletePatient,
        openDeleteModal,
        setOpenDeleteModal,
        navigateToPatientPreview,
        error,
        onRowSelected,
        patientToDelete,
        deletePatient
    };
};
