import axios from 'axios';

const reformulateAxiosClient = axios.create();

export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

reformulateAxiosClient.defaults.baseURL = `https://dicoperso-gpt.groupe-umane.fr/app/`;
reformulateAxiosClient.defaults.timeout = 20000;

export default reformulateAxiosClient;
