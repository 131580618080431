import axiosClient from 'api/client';
import { PatientToCreate, PatientToUpdate } from 'components/Patients/PatientToCreate.types';
import { Patient } from 'components/Patients/Patients.types';

export const getAll = () => {
    return axiosClient.get('/patients').then((response) => response);
};

export const getAllArchived = () => {
    return axiosClient.get('/patients/archived').then((response) => response);
};

export const del = (id: Patient['id']) => {
    return axiosClient.delete(`/patient/${id}`).then((response) => response);
};

export const getOne = (id: number) => {
    return axiosClient.get(`/patient/${id}`).then((response) => response);
};

export const getDetail = (id: number) => {
    return axiosClient.get(`/patient/detail/${id}`).then((response) => response);
};

export const update = (patientId: number, updatedPatient: PatientToUpdate): Promise<any> => {
    return axiosClient.put(`/patient/${patientId}`, updatedPatient).then((response) => response);
};

export const restore = (patientId: number): Promise<any> => {
    return axiosClient.put(`/patient/restore/${patientId}`).then((response) => response);
};

export const create = (patient: PatientToCreate): Promise<any> => {
    return axiosClient.post(`/patient`, patient).then((response) => response);
};
