import { create, update } from 'api/patients';
import { PatientToCreate, PatientToUpdate } from 'components/Patients/PatientToCreate.types';
import { BookletTab, Patient } from 'components/Patients/Patients.types';
import { useEffect, useState } from 'react';
import { BookletTabsField } from 'views/authentifiedView/AuthentifiedView';

const randomHexas = [
    '#FED501',
    '#8BCAED',
    '#FFA5C9',
    '#FF7F00',
    '#A183DD',
    '#CBE26A',
    '#F15362',
    '#FF8571',
    '#9747A5',
    '#6BBA5B',
    '#71D3C9',
    '#2F9DA8',
    '#1C52A0',
    '#5D5DBA',
    '#FFA233',
    '#EF7BCE',
    '#EF7BCE',
    '#CA3674',
    '#6899D1',
    '#357C23',
    '#BF77AB',
    '#405EDB',
    '#F2D4CC',
    '#FFBF65',
    '#F2D4CC',
    '#FB6238',
    '#01A5E4',
    '#4ED092',
    '#8499B7',
    '#FC3552',
    '#934163',
    '#FFCFCF',
    '#00A5E5'
];

const generateRandomHex = () => randomHexas[Math.floor(Math.random() * randomHexas.length)];

export const useAddPatientVm = ({
    bookletTabsFields,
    patientToUpdate,
    backAction
}: {
    bookletTabsFields: BookletTabsField[];
    patientToUpdate: Patient | undefined;
    backAction: () => void;
}) => {
    const [newPatient, setNewPatient] = useState<PatientToCreate | PatientToUpdate>(
        patientToUpdate
            ? {
                  id: patientToUpdate.id,
                  establishment_id: patientToUpdate.establishment.id,
                  emergency_contact_name: patientToUpdate.emergency_contact_name,
                  emergency_contact_number: patientToUpdate.emergency_contact_number,
                  birthdate: patientToUpdate.birthdate,
                  firstname: patientToUpdate.firstname,
                  lastname: patientToUpdate.lastname,
                  picture_id: patientToUpdate.picture_id,
                  picture: patientToUpdate.picture,
                  booklets: patientToUpdate.booklettabs
              }
            : {
                  establishment_id: 0,
                  emergency_contact_name: '',
                  emergency_contact_number: '',
                  birthdate: '',
                  firstname: '',
                  lastname: '',
                  picture_id: undefined,
                  booklets: bookletTabsFields
                      ? bookletTabsFields.map((bookletTabField, i) => ({
                            title: bookletTabField.label,
                            content: '',
                            color: randomHexas[i]
                        }))
                      : []
              }
    );
    const [colorPickerToShow, setColorPickerToShow] = useState<number | undefined>();

    const handleChange = (key: string, val: string, object?: boolean, objectProperty?: string) => {
        setNewPatient((prevNewPatient: PatientToCreate) => ({
            ...prevNewPatient,
            ...(object && objectProperty
                ? {
                      [key]: {
                          [objectProperty]: val
                      }
                  }
                : {
                      [key]: val
                  })
        }));
    };

    const updateBooklet = (index: number, val: string, key: string) => {
        const tempPatient = { ...newPatient };
        tempPatient.booklets[index][key] = val;
        setNewPatient(tempPatient);
    };

    const deleteBooklet = (index: number) => {
        const tempPatient = { ...newPatient };
        tempPatient.booklets = tempPatient.booklets.filter((b: BookletTab, i: number) => index !== i);
        setNewPatient(tempPatient);
    };

    const addBooklet = () => {
        setNewPatient((prevPatient: PatientToCreate) => ({
            ...prevPatient,
            booklets: [
                ...prevPatient.booklets,
                {
                    title: '',
                    content: '',
                    color: generateRandomHex()
                }
            ]
        }));
    };

    const onSubmit = () => {
        if (!patientToUpdate) {
            create(newPatient).then((data) => {
                backAction();
            });
        } else {
            update(newPatient.id!, newPatient as PatientToUpdate).then((data) => {
                backAction();
            });
        }
    };

    return {
        handleChange,
        newPatient,
        updateBooklet,
        addBooklet,
        deleteBooklet,
        colorPickerToShow,
        setColorPickerToShow,
        onSubmit
    };
};
