import './Form.scss';
import React, { useState } from 'react';
import { FormProps, FormStepDetail } from './Form.types';
import { FormHeader } from './FormHeader/FormHeader';
import { FormFooter } from './FormFooter/FormFooter';
import { Wizard } from 'react-use-wizard';
import { FormStep } from './FormStep/FormStep';
import { useForm } from './useForm';
import { FidgetSpinner } from 'react-loader-spinner';

export const Form: React.FC<FormProps> = ({
    title,
    steps,
    createCta,
    onSubmit,
    backAction,
    updateEntity,
    handleAddEntry,
    hasBackAction
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { entity, handleChange } = useForm({ steps, updateEntity });

    const submit = (event: any) => {
        event.preventDefault();
        setLoading(true);

        if ('booklet1' in entity) {
            const newEntity = { ...entity } as any;
            newEntity.booklettabs = [];

            Object.entries(entity).forEach(([key, val]) => {
                if (key.includes('booklet')) {
                    newEntity.booklettabs.push({
                        id: key,
                        content: val
                    });
                }
            });

            if (updateEntity) {
                onSubmit(updateEntity.id, newEntity)
                    .then((data) => {
                        setLoading(false);
                        backAction();
                    })
                    .catch((e) => {
                        setLoading(false);
                        setError('Une erreur est survenue, veuillez vérifier votre formulaire');
                    });
                return;
            }

            onSubmit(newEntity)
                .then((r) => {
                    setLoading(false);
                    backAction();
                })
                .catch((e) => {
                    setLoading(false);
                    setError('Une erreur est survenue, veuillez vérifier votre formulaire');
                });
            return;
        }

        if (updateEntity) {
            onSubmit(updateEntity.id, entity)
                .then((data) => {
                    setLoading(false);
                    backAction();
                })
                .catch((e) => {
                    setLoading(false);
                    setError('Une erreur est survenue, veuillez vérifier votre formulaire');
                });
            return;
        }

        //TODO: Handle errors
        onSubmit(entity)
            .then((r) => {
                setLoading(false);
                backAction();
            })
            .catch((e) => {
                setLoading(false);
                setError('Une erreur est survenue, veuillez vérifier votre formulaire');
            });
    };

    return (
        <div className="form">
            {loading && (
                <div className="form-loader">
                    <FidgetSpinner
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="fidget-spinner-loading"
                        wrapperStyle={{}}
                        wrapperClass="fidget-spinner-wrapper"
                        backgroundColor="#ffffff"
                        ballColors={['#fea4c9', '#fb821c', '#91c8ec']}
                    />
                </div>
            )}
            <Wizard
                header={<FormHeader title={title} steps={steps} backAction={backAction} hasBackAction={hasBackAction} />}
                footer={<FormFooter createCta={createCta} />}
            >
                {steps &&
                    steps.map((step: FormStepDetail, i: number) => (
                        <FormStep
                            step={step}
                            entity={entity}
                            handleChange={handleChange}
                            onSubmit={i + 1 === steps.length ? submit : undefined}
                            handleAddEntry={handleAddEntry}
                        ></FormStep>
                    ))}
            </Wizard>
            {error && <p className="form-error">{error}</p>}
        </div>
    );
};
