import noPicture from 'assets/images/no-camera.png';
import { Establishment, Patient } from 'components/Patients/Patients.types';
import { Button, Loader } from 'components/ui-components';
import React from 'react';
import { FaPencilAlt, FaPrint } from 'react-icons/fa';
import { FaIdCard } from 'react-icons/fa6';
import { IoEye } from 'react-icons/io5';
import { RiHome4Fill } from 'react-icons/ri';
import { TbSos } from 'react-icons/tb';
import './EntityInfos.scss';
import { useEntityInfos } from './useEntityInfos';
import { useNavigate } from 'react-router-dom';

export type EntityInfosProps = {
    entity?: Patient | Establishment;
    setPatientToUpdate: React.Dispatch<React.SetStateAction<Patient | undefined>>;
};

export const EntityInfos: React.FC<EntityInfosProps> = ({ entity, setPatientToUpdate }) => {
    const { data, navigateToPatientPreview } = useEntityInfos({ entity });
    const navigate = useNavigate();

    const print = (data: any) => {
        function ImageToPrint(source: any) {
            return (
                '<html><head><scri' +
                'pt>function step1(){\n' +
                "setTimeout('step2()', 10);}\n" +
                'function step2(){window.print();window.close()}\n' +
                '</scri' +
                "pt></head><body onload='step1()'>\n" +
                "<img src='" +
                source +
                "' /></body></html>"
            );
        }

        function PrintImage(source: any) {
            var Pagelink = 'about:blank';
            var pwa = window.open(Pagelink, '_new');
            if (pwa) {
                pwa.document.open();
                pwa.document.write(ImageToPrint(source));
                pwa.document.close();
            }
        }

        PrintImage(data.qrcode);
    };

    const navigateToCardPrint = (id: number) => {
        window.open(`${window.location.origin}/#/card/${id}`, '_blank', 'rel=noopener noreferrer');
    };

    if (!entity || !data) {
        return <></>;
    }

    return (
        <>
            <div className="entity-infos">
                <h2 className="info1">{data.info1}</h2>
                {data.type === 'patient' && <img className="picture" alt="patient" width={140} height={140} src={data.picture} />}
                <p className="info2">{data.info2}</p>
                {data.type === 'patient' && (
                    <>
                        <Button
                            icon={<IoEye />}
                            color={'yellow'}
                            text="Voir son Dico Perso"
                            onClick={() => navigateToPatientPreview(data.id)}
                        />
                        <div className="button-group">
                            <Button
                                icon={<FaPencilAlt />}
                                color={'yellow'}
                                colored={false}
                                text="Modifier"
                                onClick={() => setPatientToUpdate(entity as Patient)}
                            />
                            <Button icon={<FaPrint />} color={'yellow'} colored={false} text="Imprimer" onClick={() => print(data)} />
                        </div>
                        <div className="button-group">
                            <Button
                                icon={<FaIdCard />}
                                color={'yellow'}
                                colored={false}
                                text="Imprimer sa carte Dico Perso"
                                onClick={() => navigateToCardPrint(data.id)}
                            />
                        </div>
                        {data.qrcode && data.online && <img className="qr-code" alt="code" src={data.qrcode} />}
                        {data.emergencyContact?.name && data.emergencyContact?.number && (
                            <div className="emergency-contact">
                                <TbSos />
                                <p className="bold">{data.emergencyContact?.name}</p>
                                <p>{data.emergencyContact.number}</p>
                                <a href={`tel:${data.emergencyContact.number}`}>Contacter le numéro d'urgence</a>
                            </div>
                        )}
                        {data.establishment && (
                            <div className="establishment">
                                <RiHome4Fill />
                                <p className="bold">{data.establishment.name}</p>
                                <p>{data.establishment.address}</p>
                                <a href={`tel:${data.establishment.number}`}>Contacter l'établissement</a>
                            </div>
                        )}
                    </>
                )}

                {data.type === 'establishment' && (
                    <div>
                        <p>
                            <a href={`mailto:${data.email}`}>{data.email}</a>
                        </p>
                        <p>
                            <a href={`tel:${data.phone}`}>{data.phone}</a>
                        </p>
                    </div>
                )}
                <hr />
            </div>
        </>
    );
};
