import './UserDetail.scss';

import React from 'react';
import { useUserDetails } from './useUserDetails';

export const UserDetails: React.FC = () => {
    const { currentUser } = useUserDetails();

    return (
        <div className="user-detail">
            {currentUser && (
                <div className="user">
                    <h3 className="profile-title">Bonjour</h3>
                    <h2 className="profile-name">{currentUser.firstname}</h2>
                    <h2 className="profile-name">{currentUser.lastname}</h2>
                    <p className="profile-email">{currentUser.email}</p>
                </div>
            )}
        </div>
    );
};
