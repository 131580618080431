import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'features';

export type UserType = {
    email: string;
    firstname: string;
    fullname: string;
    id: number;
    lastname: string;
    online: boolean;
    role: string;
    created_at: string;
    connected_at: string;
};

export type AuthState = {
    isLogged: boolean;
    jwt: string | null;
    user: UserType | null;
};

const initialAuthState = {
    isLogged: false,
    jwt: null,
    user: null
};

export const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        login: (state, data) => {
            const { jwt, user } = data.payload;
            state.isLogged = true;
            state.jwt = jwt;
            state.user = user;
        },
        logout: (state) => {
            state.isLogged = false;
            state.jwt = null;
        }
    }
});
export const getIsLogged = (state: RootState) => state.authentication.isLogged;
export const getJwt = (state: RootState) => state.authentication.jwt;
export const getUser = (state: RootState): UserType | null => state.authentication.user;
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
