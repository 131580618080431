import { Header, Patients } from 'components';
import { EntityInfos } from 'components/EntityInfos/EntityInfos';
import { Establishments } from 'components/Establishments/Establishments';
import { Form } from 'components/Form/Form';
import { FormField, FormStepDetail } from 'components/Form/Form.types';
import { Establishment, Patient } from 'components/Patients/Patients.types';
import { UserDetails } from 'components/UserDetails/UserDetail';
import { Button } from 'components/ui-components';
import { getUser, logout } from 'features/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { IoIosSettings } from 'react-icons/io';
import { RiArchiveDrawerFill, RiHome4Fill, RiLogoutCircleRFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './AuthentifiedView.scss';

import { createUser } from 'api/auth';
import { create as createEstablishments, getAll, update as updateEstablishment } from 'api/establishments';
import { AddPatient } from 'components/AddPatient/AddPatient';
import { Archives } from 'components/Archives/Archives';
import { Users } from 'components/Users/Users';
import { FaCirclePlus } from 'react-icons/fa6';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { DicoCard } from 'components/DicoCard/DicoCard';

export type BookletTabsConfiguration = {
    id: number;
    title: string;
    color: string;
    position: number;
    online?: boolean;
    created_at?: string;
};

export type BookletTabsField = {
    label: string;
    property: string;
    type: FormField['type'];
    required: boolean;
};

export type BookletTabs = Record<string, string>;

export const AuthentifiedView: React.FC = () => {
    const [toggleRightPanel, setToggleRightPanel] = useState<boolean>(false);
    const [entityInfos, setEntityInfos] = useState<Patient | Establishment | undefined>();
    const [establishmentsOptions, setEstablishmentsOptions] = useState<{ value: string; label: string }[] | undefined>();

    const user = useSelector(getUser);

    const [bookletTabsFields, setBookletTabsFields] = useState<BookletTabsField[]>([
        {
            label: "Moi c'est",
            property: 'booklet1',
            type: 'wysiwyg',
            required: true
        },
        {
            label: 'Mon mode de vie, mon entourage',
            property: 'booklet2',
            type: 'wysiwyg',
            required: true
        },
        {
            label: "J'aime",
            property: 'booklet3',
            type: 'wysiwyg',
            required: true
        },
        {
            label: "Je n'aime pas",
            property: 'booklet4',
            type: 'wysiwyg',
            required: true
        },
        {
            label: 'Mon quotidien',
            property: 'booklet5',
            type: 'wysiwyg',
            required: true
        },
        {
            label: 'Ma mobilité, mes installations, mes déplacements, mes transferts',
            property: 'booklet6',
            type: 'wysiwyg',
            required: true
        },
        {
            label: 'Ma communication',
            property: 'booklet7',
            type: 'wysiwyg',
            required: true
        },
        {
            label: "Ce qu'il vous faut savoir",
            property: 'booklet8',
            type: 'wysiwyg',
            required: true
        }
    ]);

    const [showCreatePatient, setShowCreatePatient] = useState<boolean>(false);
    const [patientToUpdate, setPatientToUpdate] = useState<Patient | undefined>();
    const [showCreateEstablishment, setShowCreateEstablishment] = useState<boolean>(false);
    const [showUserTable, setShowUserTable] = useState<boolean>(false);
    const [establishmentToUpdate, setEstablishmentToUpdate] = useState<Establishment | undefined>();

    const [showCreateUser, setShowCreateUser] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const unselectAllSteps = () => {
        setShowCreatePatient(false);
        setShowCreateEstablishment(false);
        setShowUserTable(false);
        setShowCreateUser(false);
        setEstablishmentToUpdate(undefined);
        setPatientToUpdate(undefined);
    };

    const handleLogout = useCallback(() => {
        dispatch(logout());
        navigate('/');
    }, [dispatch, navigate]);

    useEffect(() => {
        getAll().then((data) => {
            const tempEstablishmentsOptions = data.data.map((e: Establishment) => ({ value: `${e.id}`, label: e.name }));
            setEstablishmentsOptions(tempEstablishmentsOptions);
        });
    }, []);

    const handleToggleRightPanel = () => {
        setToggleRightPanel((prevState) => !prevState);
    };

    useEffect(() => {
        setToggleRightPanel(showCreatePatient || showCreateEstablishment || showCreateUser || showUserTable);
    }, [showCreatePatient, showCreateEstablishment, showCreateUser, showUserTable]);

    const establishmentSteps: FormStepDetail[] = [
        {
            title: 'Informations générales',
            fields: [
                {
                    type: 'text',
                    label: 'Nom',
                    property: 'name',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Adresse',
                    property: 'address',
                    required: true
                },
                {
                    type: 'email',
                    label: 'Adresse E-mail',
                    property: 'email',
                    required: true
                },
                {
                    type: 'tel',
                    label: 'Téléphone',
                    property: 'phone',
                    required: true
                }
            ]
        }
    ];

    const userSteps: FormStepDetail[] = [
        {
            title: 'Créer un utilisateur',
            fields: [
                {
                    type: 'text',
                    label: 'Nom',
                    property: 'lastname',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Prénom',
                    property: 'firstname',
                    required: true
                },
                {
                    type: 'email',
                    label: 'Adresse E-mail',
                    property: 'email',
                    required: true
                },
                {
                    type: 'select',
                    label: 'Établissement de cette personne',
                    property: 'establishment_id',
                    required: false,
                    selectOptions: establishmentsOptions ?? []
                },
                {
                    type: 'select',
                    label: 'Type de compte',
                    property: 'role',
                    required: true,
                    selectOptions: [
                        {
                            label: 'Utilisateur',
                            value: 'user'
                        },
                        {
                            label: 'Administrateur',
                            value: 'admin'
                        }
                    ]
                }
            ]
        }
    ];

    return (
        <div className="authentified-view">
            <Tabs className="main-wrapper" onSelect={() => unselectAllSteps()}>
                <div className="left">
                    <div>
                        <UserDetails />

                        <div className="navigation">
                            <TabList>
                                <Tab className="navigation-item" onClick={() => setToggleRightPanel(false)}>
                                    <FaUser />
                                    <span>Personnes</span>
                                </Tab>
                                <Tab className="navigation-item" onClick={() => setToggleRightPanel(false)}>
                                    <RiHome4Fill />
                                    <span>Établissements</span>
                                </Tab>
                                <Tab className="navigation-item" onClick={() => setToggleRightPanel(false)}>
                                    <RiArchiveDrawerFill />
                                    <span>Archives</span>
                                </Tab>
                            </TabList>
                        </div>
                    </div>

                    <div className="settings">
                        <div className="settings-items">
                            {user && user.role === 'admin' && (
                                <>
                                    <Button
                                        text="Créer un utilisateur"
                                        icon={<FaCirclePlus style={{ fontSize: '24px' }} />}
                                        colored={false}
                                        settings={true}
                                        onClick={() => {
                                            setShowCreateUser(true);
                                            setShowUserTable(false);
                                        }}
                                    />
                                    <Button
                                        text="Paramètres"
                                        icon={<IoIosSettings />}
                                        colored={false}
                                        settings={true}
                                        onClick={() => {
                                            setShowUserTable(true);
                                            setShowCreateUser(false);
                                        }}
                                    />
                                </>
                            )}

                            <Button
                                text="Déconnexion"
                                icon={<RiLogoutCircleRFill />}
                                colored={false}
                                settings={true}
                                onClick={handleLogout}
                            />
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <Header />

                    {!showCreateUser && !showUserTable && (
                        <>
                            <TabPanel className="tab-panel">
                                {!showCreatePatient && !patientToUpdate && (
                                    <>
                                        <Patients
                                            setEntityInfos={setEntityInfos}
                                            setShowCreatePatient={setShowCreatePatient}
                                            setPatientToUpdate={setPatientToUpdate}
                                        />
                                        <DicoCard />
                                    </>
                                )}

                                {showCreatePatient && (
                                    <AddPatient
                                        bookletTabsFields={bookletTabsFields}
                                        backAction={() => setShowCreatePatient(false)}
                                        createCta={'Ajouter cette personne'}
                                    />
                                )}

                                {patientToUpdate && (
                                    <AddPatient
                                        bookletTabsFields={bookletTabsFields}
                                        backAction={() => setPatientToUpdate(undefined)}
                                        patientToUpdate={patientToUpdate}
                                        createCta={'Modifier cette personne'}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel className="tab-panel">
                                {!showCreateEstablishment && !establishmentToUpdate && (
                                    <Establishments
                                        setEntityInfos={setEntityInfos}
                                        setShowCreateEstablishment={setShowCreateEstablishment}
                                        setEstablishmentToUpdate={setEstablishmentToUpdate}
                                    />
                                )}

                                {showCreateEstablishment && (
                                    <Form
                                        title="Ajouter un établissement"
                                        createCta="Ajouter l'établissement"
                                        onSubmit={createEstablishments}
                                        steps={establishmentSteps}
                                        backAction={() => setShowCreateEstablishment(false)}
                                    />
                                )}

                                {establishmentToUpdate && (
                                    <Form
                                        title={`Modifier ${establishmentToUpdate.name}`}
                                        createCta="Modifier cet établissement"
                                        onSubmit={updateEstablishment}
                                        steps={establishmentSteps}
                                        backAction={() => setEstablishmentToUpdate(undefined)}
                                        hasBackAction
                                        updateEntity={establishmentToUpdate}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel className="tab-panel">
                                <Archives setEntityInfos={setEntityInfos} />
                            </TabPanel>
                        </>
                    )}

                    {showCreateUser && (
                        <div className="create-user">
                            <Form
                                title="Créer un utilisateur"
                                backAction={() => setShowCreateUser(false)}
                                onSubmit={createUser}
                                steps={userSteps}
                                hasBackAction
                            />
                        </div>
                    )}

                    {showUserTable && <Users goBack={setShowUserTable} />}

                    <Button
                        className={'toggle-button' + (!toggleRightPanel ? ' rotated' : '')}
                        icon={<IoMdArrowRoundBack />}
                        color="lightyellow"
                        onClick={() => handleToggleRightPanel()}
                    />
                </div>

                <div className={'right' + (toggleRightPanel ? ' toggled' : '')}>
                    <EntityInfos entity={entityInfos} setPatientToUpdate={setPatientToUpdate} />
                </div>
            </Tabs>
        </div>
    );
};
