import axios from 'axios';
import { store } from 'features/index';

const axiosClient = axios.create();

export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

axiosClient.defaults.baseURL = `${BASE_API_URL}/app`;
axiosClient.defaults.timeout = 20000;

axiosClient.interceptors.request.use(
    async (config) => {
        const jwt = store.getState().authentication.jwt;
        config.headers.jwt = jwt;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosClient;
