import { LoginPayloadType, LoginResponse, login } from 'api/auth';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { login as loginDispatch } from 'features/auth';
import { useState } from 'react';

export const useLoginForm = () => {
    const [error, setError] = useState<string>('');
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values: LoginPayloadType) => {
            setError('');
            login(values)
                .then((data) => {
                    const { data: loginResponse } = data;
                    dispatch(loginDispatch(loginResponse));
                })
                .catch((err) => {
                    setError(err.response.data.message);
                });
        }
    });
    return { formik, error };
};
