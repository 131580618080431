import { Button, Loader } from 'components/ui-components';
import { Logo } from 'components/ui-components/Logo/Logo';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import './Patient.scss';
import { usePatient } from './usePatient';

import { RiHome4Fill } from 'react-icons/ri';
import { TbSos } from 'react-icons/tb';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import noPicture from 'assets/images/no-camera.png';

export type PatientProps = {
    patientId?: string;
};

export const Patient: React.FC<PatientProps> = ({ patientId }) => {
    const {
        patient,
        tabIndex,
        handleSetTabIndex,
        scrollPosition,
        executeScroll,
        elementRefs,
        patientDetailsRef,
        heightPatientDetails,
        patientHeaderRef,
        heightPatienHeader,
        callTel
    } = usePatient(patientId);

    if (!patient) {
        return <Loader />;
    }

    return (
        <div className="patient-public">
            {patient && patient.booklettabs && (
                <Tabs
                    key={`tab-${tabIndex}`}
                    selectedIndex={tabIndex}
                    onSelect={(index) => {
                        handleSetTabIndex(index);
                    }}
                    forceRenderTabPanel={true}
                    className="scrolling-menu"
                >
                    <div className={'patient-public-header' + (scrollPosition > 50 ? ' sticky' : '')} ref={patientHeaderRef}>
                        <Logo isMobile={true} />
                        <p className="patient-details-name">
                            {patient.firstname} {patient.lastname}
                        </p>
                        <TabList>
                            {patient.booklettabs.map((booklet, index) => (
                                <Tab key={`${booklet.title}-${index}`} style={{ '--bottom-color': booklet.color } as React.CSSProperties}>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            executeScroll(index);
                                        }}
                                    >
                                        {booklet.title}
                                    </div>
                                </Tab>
                            ))}
                        </TabList>
                    </div>

                    {patient && (
                        <div className="patient-details" ref={patientDetailsRef}>
                            <img src={patient?.picture?.view_link ?? noPicture} className="patient-details-picture" />

                            <h3 className="patient-details-emergency-contact-title">Numéro d'urgence</h3>
                            <p className="patient-details-emergency-contact-name">{patient?.emergency_contact_name}</p>
                            <p className="patient-details-emergency-contact-number">{patient?.emergency_contact_number}</p>

                            <h3 className="patient-details-establishment-title">Établissement</h3>
                            <p className="patient-details-establishment-name">{patient.establishment.name}</p>
                            <p className="patient-details-establishment-number">{patient.establishment.address}</p>

                            <div className={'buttons-actions'}>
                                <Button
                                    text=""
                                    isAnAction
                                    icon={<TbSos />}
                                    colored={true}
                                    onClick={() => callTel(patient.emergency_contact_number)}
                                />
                                <Button
                                    text=""
                                    isAnAction
                                    icon={<RiHome4Fill />}
                                    colored={true}
                                    onClick={() => callTel(patient.establishment.phone)}
                                />
                            </div>

                            {heightPatientDetails && heightPatienHeader && (
                                <div
                                    className={
                                        'buttons-actions floating' +
                                        (scrollPosition > heightPatientDetails - heightPatienHeader ? ' show' : '')
                                    }
                                >
                                    <Button
                                        text=""
                                        isAnAction
                                        icon={<TbSos />}
                                        colored={true}
                                        onClick={() => callTel(patient.emergency_contact_number)}
                                    />
                                    <Button
                                        text=""
                                        isAnAction
                                        icon={<RiHome4Fill />}
                                        colored={true}
                                        onClick={() => callTel(patient.establishment.phone)}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {patient.booklettabs.map((booklet, index) => (
                        <TabPanel forceRender key={`tab-panel-${booklet.title}-${index}`}>
                            <div
                                ref={(element) => {
                                    elementRefs.current[index] = element as HTMLDivElement;
                                }}
                            >
                                <h3>
                                    <svg
                                        className="colored-sun"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38.861"
                                        height="38.861"
                                        viewBox="0 0 38.861 38.861"
                                    >
                                        <path
                                            id="Tracé_508"
                                            data-name="Tracé 508"
                                            d="M137.669,32.442l5.222-3.075-5.914-1.318,4.012-4.54-6.031.583,2.411-5.559-5.558,2.412.583-6.031-4.54,4.012-1.318-5.914-3.075,5.223-3.075-5.223-1.318,5.914-4.54-4.011.584,6.031-5.559-2.411,2.412,5.558-6.031-.583,4.012,4.54-5.914,1.318,5.222,3.075-5.222,3.075,5.914,1.318-4.012,4.541,6.031-.583-2.411,5.558,5.558-2.411-.584,6.031,4.54-4.012,1.318,5.914,3.075-5.222,3.075,5.222,1.318-5.914,4.54,4.012-.583-6.031,5.559,2.411-2.412-5.558,6.031.583-4.012-4.54,5.914-1.318Zm-14.208,9.044a9.224,9.224,0,1,1,9.225-9.225,9.225,9.225,0,0,1-9.225,9.225"
                                            transform="translate(-104.03 -13.011)"
                                            fill={booklet.color}
                                        />
                                    </svg>
                                    {booklet.title}
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: booklet.content }}></p>
                            </div>
                        </TabPanel>
                    ))}
                </Tabs>
            )}
        </div>
    );
};
