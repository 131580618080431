import { getAllArchived as getAllPatientsArchived, restore as restorePatient } from 'api/patients';
import { getAllArchived as getAllEstablishmentsArchived, restore as restoreEstablishment } from 'api/establishments';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Establishment, Establishments, Patient, Patients } from 'components/Patients/Patients.types';
import { FaTrashRestoreAlt } from 'react-icons/fa';

export type AvailableEntitiesType = 'personnes' | 'etablissements';
export type AvailableEntities = Patients | Establishments;
export type AvailableEntity = Patient | Establishment;
export type ArchivedEntitiesType = Record<AvailableEntitiesType, AvailableEntities>;

export type useArchivesProps = {
    setEntityInfos?: any;
};

export const useArchives = ({ setEntityInfos }: useArchivesProps) => {
    const [selectedEntityType, setSelectedEntityType] = useState<AvailableEntitiesType>('personnes');
    const [archivedEntities, setArchivedEntities] = useState<ArchivedEntitiesType | undefined>();

    const [openRestoreModal, setOpenRestoreModal] = useState<boolean>(false);
    const [entityToRestore, setEntityToRestore] = useState<AvailableEntity>();

    const fetchArchivedEntities = () => {
        axios.all([getAllPatientsArchived(), getAllEstablishmentsArchived()]).then(
            axios.spread((patientsData, establishmentsData) => {
                const { data: patients } = patientsData;
                const { data: establishments } = establishmentsData;
                setArchivedEntities({
                    personnes: patients,
                    etablissements: establishments
                });
            })
        );
    };

    const handleRestoreEntity = (entity: AvailableEntity) => {
        setEntityToRestore(entity);
        setOpenRestoreModal(true);
    };

    const handleUpdateSelectedEntity = (newEntityType: AvailableEntitiesType) => {
        if (newEntityType === selectedEntityType) return;
        setSelectedEntityType(newEntityType);
    };

    const ActionButtonsRenderer = (props: any) => {
        const { data } = props;

        return (
            <div className="actions">
                <button title="Restaurer" onClick={() => handleRestoreEntity(data)} className="action unstyled">
                    <FaTrashRestoreAlt className="icon" />
                </button>
            </div>
        );
    };

    const restoreEntity = async (entity: AvailableEntity | undefined) => {
        if (!entity) return;
        switch (selectedEntityType) {
            case 'personnes':
                await restorePatient(entity.id);
                fetchArchivedEntities();
                break;
            case 'etablissements':
                await restoreEstablishment(entity.id);
                fetchArchivedEntities();
                break;
            default:
                break;
        }
    };

    const establishmentColumns = [
        { headerName: 'Nom', field: 'name' },
        { headerName: 'Adresse', field: 'address' },
        { headerName: 'Contact', field: 'email' },
        { headerName: 'N° de téléphone', field: 'phone' },
        { headerName: 'Actions', cellRenderer: ActionButtonsRenderer, maxWidth: 100, minWidth: 100 }
    ];

    const patientsColumns = [
        { headerName: 'Nom', field: 'lastname' },
        { headerName: 'Prénom', field: 'firstname' },
        { headerName: 'Établissement', valueGetter: (params: any) => params.data?.establishment?.name ?? '/' },
        { headerName: 'Personne à contacter', valueGetter: (params: any) => params.data?.emergency_contact_name ?? '/' },
        { headerName: 'Numéro urgence', valueGetter: (params: any) => params.data?.emergency_contact_number ?? '/' },
        { headerName: 'Actions', cellRenderer: ActionButtonsRenderer, maxWidth: 100, minWidth: 100 }
    ];

    const computeRowsAndColumns = useCallback(() => {
        if (!archivedEntities) return;
        return {
            ...(selectedEntityType === 'etablissements'
                ? {
                      columns: establishmentColumns,
                      rows: archivedEntities['etablissements']
                  }
                : {
                      columns: patientsColumns,
                      rows: archivedEntities['personnes']
                  })
        };
    }, [selectedEntityType, archivedEntities]);

    useEffect(() => {
        fetchArchivedEntities();
    }, []);

    useEffect(() => {
        if (!archivedEntities || !selectedEntityType || !setEntityInfos) return;
        setEntityInfos(archivedEntities[selectedEntityType][0]);
    }, [archivedEntities, selectedEntityType, setEntityInfos]);

    const onRowSelected = useCallback(
        (event: any) => {
            if (!event.node.selected || !setEntityInfos) return;
            setEntityInfos(event.data);
        },
        [setEntityInfos]
    );

    return {
        selectedEntityType,
        handleUpdateSelectedEntity,
        formData: computeRowsAndColumns(),
        onRowSelected,
        handleRestoreEntity,
        restoreEntity,
        entityToRestore,
        openRestoreModal,
        setOpenRestoreModal
    };
};
