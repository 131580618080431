import React, { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { FormField, FormStepDetail } from '../Form.types';
import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnNumberedList,
    BtnUnderline,
    Editor,
    EditorProvider,
    Separator,
    Toolbar
} from 'react-simple-wysiwyg';
import { UserFile, useFlowJs } from './useFlowJs';
import PasswordChecklist from 'react-password-checklist';
import gallery2 from 'assets/images/gallery-2.png';
import { Button } from 'components/ui-components';

export type FormStepProps = {
    step: FormStepDetail;
    entity: { [key: string]: string | UserFile | object };
    handleChange: (key: string, val: string | UserFile, object?: boolean, objectProperty?: string) => void;
    onSubmit: ((event: any) => void) | undefined;
    handleAddEntry?: any;
};

export const FormStep: React.FC<FormStepProps> = ({ step: { title, fields, type }, entity, handleChange, onSubmit, handleAddEntry }) => {
    const { nextStep } = useWizard();
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(true);
    const { flowDropRef, document, setDocument } = useFlowJs();

    useEffect(() => {
        if (!document) return;

        handleChange('picture_id', document.id.toString());
        handleChange('picture', {
            id: document.id,
            view_link: document.view_link,
            mime_type: document.mime_type,
            original_filename: document.original_filename
        });
    }, [document]);

    useEffect(() => {
        if (entity?.picture) {
            setDocument(entity.picture as UserFile);
        }
    }, []);

    return (
        <div className="step">
            <div className="title-step">{title}</div>
            <form id="form-patient" className="form" onSubmit={onSubmit ?? nextStep}>
                {type !== 'crud' &&
                    fields &&
                    fields.map((field: FormField, i: number) => {
                        switch (field.type) {
                            case 'wysiwyg':
                                return (
                                    <div className="wysiwyg" key={`generated-wysiwyg-${i}`}>
                                        <label>
                                            {field.label} {field.required && '*'}
                                        </label>
                                        <EditorProvider>
                                            <Editor
                                                value={entity[field.property] as string}
                                                onChange={(e) => handleChange(field.property, e.target.value)}
                                            >
                                                <Toolbar>
                                                    <BtnBold />
                                                    <BtnItalic />
                                                    <BtnUnderline />
                                                    <Separator />
                                                    <BtnBulletList />
                                                    <BtnNumberedList />
                                                </Toolbar>
                                            </Editor>
                                        </EditorProvider>
                                    </div>
                                );

                            case 'select':
                                return (
                                    <div className="input select">
                                        <label htmlFor={field.property}>
                                            {field.label} {field.required && '*'}
                                        </label>
                                        <select
                                            id={field.property}
                                            name={field.property}
                                            required={field.required}
                                            value={entity[field.property] as string}
                                            onChange={(e) => handleChange(field.property, e.target.value)}
                                        >
                                            <option value="">Champ non rempli</option>
                                            {field.selectOptions?.length &&
                                                field.selectOptions.map((option, index) => (
                                                    <option className="name-establishment" value={option.value} key={index}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                );

                            case 'file':
                                return (
                                    <div className="input">
                                        <label>
                                            {field.label} {field.required && '*'}
                                        </label>
                                        <div ref={flowDropRef} className="flow-container">
                                            {!document && <img src={gallery2} className="no-image" />}
                                            {document && <img src={document.view_link} className="uploaded-image" />}
                                        </div>
                                    </div>
                                );

                            default:
                                return (
                                    <div className="input" key={`generated-input-${i}`}>
                                        <label htmlFor={field.property}>
                                            {field.label} {field.required && '*'}
                                        </label>

                                        <input
                                            type={field.type}
                                            id={field.property}
                                            name={field.property}
                                            {...(field.pattern && { pattern: field.pattern })}
                                            onChange={(e) => handleChange(field.property, e.target.value)}
                                            required={field.required}
                                            placeholder={field.placeholder ?? field.label}
                                            value={entity[field.property] as string}
                                        />

                                        {field.type == 'password' && (
                                            <>
                                                <PasswordChecklist
                                                    rules={['minLength', 'specialChar', 'number']}
                                                    minLength={8}
                                                    value={(entity[field.property] as string) ?? ''}
                                                    onChange={(isValid) => {
                                                        setPasswordIsValid(isValid);
                                                    }}
                                                    messages={{
                                                        minLength: 'Le mot de passe doit contenir au moins 8 caractères',
                                                        specialChar: 'Le mot de passe doit contenir au moins un caractère spécial',
                                                        number: 'Le mot de passe doit contenir au moins un chiffre'
                                                    }}
                                                />
                                            </>
                                        )}

                                        {field.subtitle && <p className="subtitle">{field.subtitle}</p>}
                                    </div>
                                );
                        }
                    })}

                {type === 'crud' && fields && (
                    <>
                        <Button
                            text="Ajouter un onglet"
                            className="abs"
                            onClick={(e) => {
                                e.preventDefault();
                                handleAddEntry();
                            }}
                        />
                        {fields &&
                            fields.map((field, i) => (
                                <div className="wysiwyg" key={`generated-wysiwyg-${i}`}>
                                    {/* <input placeholder="Titre de l'onglet" value={entity.booklet[field.property].label} onChange={(e) => handleChange(field.property, e.target.value, true, "label")}></input>
                                <EditorProvider>
                                    <Editor
                                        value={entity.booklet[field.property].content}
                                        onChange={(e) => handleChange(field.property, e.target.value, true, 'content')}
                                    >
                                        <Toolbar>
                                            <BtnBold />
                                            <BtnItalic />
                                            <BtnUnderline />
                                            <Separator />
                                            <BtnBulletList />
                                            <BtnNumberedList />
                                        </Toolbar>
                                    </Editor>
                                </EditorProvider> */}
                                </div>
                            ))}
                    </>
                )}
            </form>
        </div>
    );
};
