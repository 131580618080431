import { getUser } from 'features/auth';
import { useSelector } from 'react-redux';

export const useUserDetails = () => {
    const currentUser = useSelector(getUser);

    return {
        currentUser
    };
};
