import { Logo } from 'components/ui-components/Logo/Logo';
import './Header.scss';
import { useHeaderVm } from './useHeaderVm';

export const Header = () => {
    const { handleLogoClick } = useHeaderVm();
    return (
        <div className="header">
            <Logo onClick={handleLogoClick} />
        </div>
    );
};
