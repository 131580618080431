import './AddPatient.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useEstablishments } from './../Establishments/useEstablishments';
import { useWizard } from 'react-use-wizard';
import { PatientToCreate } from 'components/Patients/PatientToCreate.types';
import { useFlowJs } from 'components/Form/FormStep/useFlowJs';

import gallery2 from 'assets/images/gallery-2.png';
import { FidgetSpinner } from 'react-loader-spinner';

import { CropperRef, Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { Button, Modal } from 'components/ui-components';

export type Step1Props = {
    handleChange: any;
    newPatient: PatientToCreate;
};

export const Step1: React.FC<Step1Props> = ({ handleChange, newPatient }) => {
    const { handleStep, nextStep } = useWizard();
    const { establishments } = useEstablishments();
    const { flowDropRef, document, setDocument, error, uploadLoading, flow } = useFlowJs();

    const cropperRef = useRef<CropperRef>(null);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [editImage, setEditImage] = useState<boolean>(false);

    const handleCrop = async () => {
        const cropper = cropperRef.current;

        if (document && cropper) {
            const canvas = cropper.getCanvas();
            canvas?.toBlob((blob) => {
                if (blob) {
                    flow.addFile(
                        new File([blob], document.original_filename, { type: document.mime_type, lastModified: new Date().getTime() })
                    );
                }
            });
        }
    };

    useEffect(() => {
        if (!document) return;

        handleChange('picture_id', document.id.toString());
        handleChange('picture', {
            id: document.id,
            view_link: document.view_link,
            mime_type: document.mime_type,
            original_filename: document.original_filename
        });
    }, [document]);

    useEffect(() => {
        if (!newPatient) return;
        if (!newPatient.picture || document) return;

        setDocument(newPatient.picture);
    }, [newPatient]);

    return (
        <div className="step-one step">
            <div className="title-step">Information générale</div>
            <form id="form-patient-dico" className="form" onSubmit={nextStep}>
                <div className="input">
                    <label htmlFor="lastname">Nom</label>
                    <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        onChange={(e) => handleChange('lastname', e.target.value)}
                        required
                        placeholder="Nom de la personne"
                        value={newPatient.lastname}
                    />
                </div>
                <div className="input">
                    <label htmlFor="firstname">Prénom</label>
                    <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        onChange={(e) => handleChange('firstname', e.target.value)}
                        required
                        placeholder="Prénom de la personne"
                        value={newPatient.firstname}
                    />
                </div>
                <div className="input">
                    <label htmlFor="birthday">Date de naissance</label>
                    <input
                        type="date"
                        className="birthdate"
                        value={newPatient.birthdate}
                        onChange={(e) => handleChange('birthdate', e.target.value)}
                    ></input>
                </div>
                <div className="input">
                    <label htmlFor="establishment">Etablissement attribué</label>
                    <select
                        required
                        onChange={(e) => handleChange('establishment_id', +e.target.value)}
                        value={newPatient.establishment_id}
                    >
                        <option value="">Choisissez un établissement</option>
                        {establishments &&
                            establishments.map((establishment, index) => (
                                <option className="name-establishment" value={establishment.id} key={index}>
                                    {establishment.name}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="input">
                    <label htmlFor="name-contact">Nom et prénom de la personne à contacter</label>
                    <input
                        type="text"
                        id="name-contact"
                        name="name-contact"
                        onChange={(e) => handleChange('emergency_contact_name', e.target.value)}
                        required
                        placeholder="Nom"
                        value={newPatient.emergency_contact_name}
                    />
                </div>
                <div className="input">
                    <label htmlFor="phone">Numéro de téléphone d'urgence</label>
                    <input
                        type="tel"
                        id="phone"
                        required
                        name="phone"
                        onChange={(e) => handleChange('emergency_contact_number', e.target.value)}
                        placeholder="Numéro"
                        value={newPatient.emergency_contact_number}
                    ></input>
                </div>

                <div className="input">
                    <label>Photo</label>
                    <div ref={flowDropRef} className="flow-container">
                        {!document && <img src={gallery2} className="no-image" />}
                        {document && <img src={document.view_link} className="uploaded-image" />}
                        {uploadLoading && (
                            <div className="form-loader">
                                <FidgetSpinner
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="fidget-spinner-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="fidget-spinner-wrapper"
                                    backgroundColor="#ffffff"
                                    ballColors={['#fea4c9', '#fb821c', '#91c8ec']}
                                />
                            </div>
                        )}
                    </div>
                    <p className="subtitle img-subtitle">
                        Taille maximum : <b>5 Mo</b> <br /> Types acceptés : <b>.png</b> et <b>.jpg</b>
                    </p>
                    {document && (
                        <div className="mt-15">
                            <Button type="button" onClick={() => setEditImage(true)} text="Recadrer la photo" />
                        </div>
                    )}

                    {error && <p className="form-error">{error}</p>}
                </div>

                <Modal
                    title={`Recadrer la photo`}
                    open={editImage}
                    onValidate={() => {
                        handleCrop();
                        setEditImage(false);
                    }}
                    isValidateDestructive={true}
                    yesText="Sauvegarder"
                    noText="Annuler"
                    setClose={() => setEditImage(false)}
                >
                    {document && <Cropper src={document.view_link} ref={cropperRef} className={'cropper'} />}
                </Modal>
            </form>
        </div>
    );
};
