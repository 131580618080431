import axiosClient from 'api/client';
import { Establishment } from 'components/Patients/Patients.types';

export const getAll = () => {
    return axiosClient.get('/establishments').then((response) => response);
};

export const getAllArchived = () => {
    return axiosClient.get('/establishments/archived').then((response) => response);
};

export const del = (id: Establishment['id']) => {
    return axiosClient.delete(`/establishment/${id}`).then((response) => response);
};

export const getOne = (id: number) => {
    return axiosClient.get(`/establishment/${id}`).then((response) => response);
};

export const update = (establishmentId: number, updatedEstablishment: Establishment): Promise<any> => {
    return axiosClient.put(`/establishment/${establishmentId}`, updatedEstablishment).then((response) => response);
};

export const restore = (establishmentId: number): Promise<any> => {
    return axiosClient.put(`/establishment/restore/${establishmentId}`).then((response) => response);
};

export const create = (establishment: Establishment): Promise<any> => {
    return axiosClient.post(`/establishment`, establishment).then((response) => response);
};
