import axiosClient from 'api/client';

export type LoginPayloadType = {
    email: string;
    password: string;
};

type UserType = {
    connected_at: string;
    created_at: string;
    email: string;
    firstname: string;
    fullname: string;
    id: number;
    lastname: string;
    online: boolean;
    role: string;
};

export type LoginResponse = {
    jwt: string;
    user: UserType;
};

export const login = (data: LoginPayloadType) => {
    return axiosClient.post('/auth/login', data).then((response) => response);
};

export const register = ({ password, token }: { password: string; token: string }) => {
    return axiosClient.post('/auth/token', { password, token }).then((response) => response);
};

export const createUser = (data: any) => {
    return axiosClient.post('/auth/register', data).then((response) => response);
};

export const list = () => {
    return axiosClient.get('/auth/list').then((response) => response);
};

export const revokeAccess = (id: number) => {
    return axiosClient.get(`/auth/revokeAccess/${id}`).then((response) => response);
};

export const getUserFromToken = (token: string) => {
    return axiosClient.get(`/auth/token?token=${token}`).then((response) => response);
};
