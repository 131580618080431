import { useEffect, useState } from 'react';
import { FormStepDetail } from './Form.types';
import { UserFile } from './FormStep/useFlowJs';

export type useFormProps = {
    steps: FormStepDetail[];
    updateEntity?: any;
};

export const useForm = ({ steps, updateEntity }: useFormProps) => {
    const [entity, setEntity] = useState<{ [key: string]: string | UserFile | object }>(updateEntity ?? {});

    useEffect(() => {
        if (!steps) return;
        const allFields = steps.map(({ fields }) => fields.map((field) => field.property));

        const tempEntity: { [key: string]: string | UserFile | object } = {};
        allFields.flat().forEach((field: string) => {
            tempEntity[field] = updateEntity?.[field] ?? '';
        });

        if (updateEntity?.picture) {
            tempEntity.picture = updateEntity.picture;
        }

        // Links the existing establishment in the form
        if (tempEntity?.establishment_id === '' && updateEntity?.establishment) {
            tempEntity.establishment_id = `${updateEntity.establishment.id}`;
        }

        // Links the existing booklettabs in the form
        if ('booklet1' in tempEntity && updateEntity?.booklettabs.length) {
            tempEntity.booklet = {};
            Object.entries(updateEntity.booklettabs).forEach(([bookletId, booklet]) => {
                const index = parseInt(bookletId);
                // @ts-expect-error
                tempEntity.booklet[`booklet${index + 1}`] = {
                    // @ts-expect-error
                    label: booklet.title,
                    content: updateEntity.booklettabs[index]?.content ?? ''
                };
            });
        }

        setEntity(tempEntity);
    }, [steps, updateEntity]);

    const handleChange = (key: string, val: string | UserFile, object?: boolean, objectProperty?: string) => {
        setEntity((prevEntity) => ({
            ...prevEntity,
            ...(object && objectProperty
                ? {
                      [key]: {
                          [objectProperty]: val
                      }
                  }
                : {
                      [key]: val
                  })
        }));
    };

    return {
        entity,
        handleChange
    };
};
