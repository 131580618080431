import { BASE_API_URL } from 'api/client';
import { Establishment, Patient } from 'components/Patients/Patients.types';
import noPicture from 'assets/images/no-camera.png';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type useEntityInfosProps = {
    entity?: Patient | Establishment;
};

export const useEntityInfos = ({ entity }: useEntityInfosProps) => {
    const navigate = useNavigate();

    const formatDate = (date: string) => {
        var dateObj = new Date(date);

        var jour = dateObj.getDate();
        var mois = dateObj.getMonth() + 1;
        var annee = dateObj.getFullYear();

        var dateFormatee = jour + '-' + (mois < 10 ? '0' : '') + mois + '-' + annee;
        return dateFormatee;
    };

    let computedEntity = useMemo(() => {
        if (!entity) return;
        if ((entity as any)?.firstname) {
            const patient: Patient = entity as Patient;
            return {
                id: patient?.id ?? 0,
                type: 'patient',
                info1: patient?.firstname ?? '' + ' ' + patient?.lastname ?? '',
                info2: patient?.birthdate ? formatDate(patient.birthdate) : "Pas de date d'anniversaire",
                picture: patient?.picture?.view_link ?? noPicture,
                qrcode: BASE_API_URL + '/app/patient/code/' + patient?.id ?? 0 + '?size=175',
                emergencyContact: {
                    number: patient?.emergency_contact_number ?? 'Non spécifié',
                    name: patient?.emergency_contact_name ?? 'Non spécifié'
                },
                establishment: {
                    name: patient?.establishment?.name ?? 'Non spécifié',
                    address: patient?.establishment?.address ?? 'Non spécifié',
                    number: patient?.establishment?.phone ?? 'Non spécifié'
                },
                online: patient?.online ?? true
            };
        }
        const establishment: Establishment = entity as Establishment;
        return {
            id: establishment?.id ?? 0,
            type: 'establishment',
            info1: establishment?.name ?? 'Non spécifié',
            info2: establishment?.address ?? 'Non spécifié',
            phone: establishment?.phone ?? 'Non spécifié',
            email: establishment?.email ?? 'Non spécifié',
            picture: 'https://placehold.co/150x150/EEE/31343C'
        };
    }, [entity]);

    const navigateToPatientPreview = (id: number) => {
        navigate(`/patient/preview/${id}`);
    };

    return {
        data: computedEntity,
        navigateToPatientPreview
    };
};
