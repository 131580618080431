import React, { useEffect, useState } from 'react';
import './DicoCard.scss';

import logo from 'assets/logos/logo.png';
import establishmentLogo from 'assets/images/establishment-card.svg';
import cardArrow from 'assets/images/card-arrow.svg';
import { useParams } from 'react-router-dom';
import { Patient } from 'components/Patients/Patients.types';
import { getOne } from 'api/patients';
import { BASE_API_URL } from 'api/client';

export const DicoCard: React.FC = () => {
    const [patient, setPatient] = useState<Patient>();
    const [error, setError] = useState<string>();
    const [generatedCanvas, setGeneratedCanvas] = useState<HTMLCanvasElement>();

    const { id } = useParams();

    useEffect(() => {
        if (!id) return;
        getOne(parseInt(id))
            .then((data) => {
                setPatient(data.data);
                setTimeout(() => {
                    window.print();
                }, 1000);
            })
            .catch(() => {
                setError('Une erreur est survenue... Veuillez réessayer plus tard');
            });
    }, [id]);

    return (
        <div className="dico-card-wrapper">
            {patient && !generatedCanvas && (
                <>
                    <div className="dico-card">
                        <div className="patient-detail">
                            <div className="patient-detail-wrapper">
                                <div className="patient-picture">
                                    <img src={patient.picture?.view_link ?? ''} className="picture" />
                                </div>
                                <p className="learn-more">Vous souhaitez en apprendre plus sur moi ?</p>
                                <p className="discover">Découvrez mon Dico Perso !</p>
                                <img src={cardArrow} className="card-arrow" />
                            </div>
                        </div>
                        <div className="patient-infos">
                            <div className="patient-infos-wrapper">
                                <h3 className="patient-name">
                                    {patient.firstname} {patient.lastname}
                                </h3>
                                <p className="establishment">
                                    <img src={establishmentLogo} width={30} height={27} style={{ marginRight: '15px' }} />
                                    {patient.establishment.name}
                                </p>
                                <p className="establishment-address">{patient.establishment.address}</p>

                                <img src={BASE_API_URL + '/app/patient/code/' + patient?.id ?? 0 + '?size=350'} className="qr-code" />
                            </div>
                        </div>
                        <img src={logo} className="logo-dico" />
                    </div>
                </>
            )}
            {!patient && error && <p className="error">{error}</p>}
        </div>
    );
};
