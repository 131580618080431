import './Preview.scss';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/ui-components';
import { IoArrowBack } from 'react-icons/io5';

export const Preview: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/#/dico-perso');
    };

    return (
        <div className="phone preview patient-details-wrapper">
            <Button className="absolute back" icon={<IoArrowBack />} text="Retour" onClick={handleBack} />

            <div className="iphone-outlined"></div>

            <div>
                <iframe id="iframe-preview" src={`/#/patient/${id}?preview=1`} />
            </div>
        </div>
    );
};
