import './PatientView.scss';

import { Patient } from 'components/Patient/Patient';
import React from 'react';
import { useParams } from 'react-router-dom';

export const PatientView: React.FC = () => {
    const { id } = useParams();
    return (
        <div className="patient-details-wrapper">
            <Patient patientId={id} />
        </div>
    );
};
