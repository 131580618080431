import './Establishments.scss';

import React, { useEffect } from 'react';
import { useEstablishments } from './useEstablishments';
import { Establishment } from 'components/Patients/Patients.types';
import { FaBoxArchive } from 'react-icons/fa6';
import { DEFAULT_PAGINATION_SIZE, Table } from 'components/ui-components/Table/Table';
import { Button, InputSearch, Modal } from 'components/ui-components';
import { FaPlus } from 'react-icons/fa';
import { BsFillPencilFill } from 'react-icons/bs';

export type EstablishmentsProps = {
    setEntityInfos?: any;
    setShowCreateEstablishment: React.Dispatch<React.SetStateAction<boolean>>;
    setEstablishmentToUpdate: React.Dispatch<React.SetStateAction<Establishment | undefined>>;
};

export const Establishments: React.FC<EstablishmentsProps> = ({ setEntityInfos, setShowCreateEstablishment, setEstablishmentToUpdate }) => {
    const {
        establishments,
        search,
        handleSetSearch,
        handleDeleteEstablishment,
        openDeleteModal,
        error,
        deleteEstablishment,
        establishmentToDelete,
        setOpenDeleteModal
    } = useEstablishments();

    //Move to useEstablishments
    useEffect(() => {
        if (establishments && establishments.length > 0) setEntityInfos(establishments[0]);
    }, [establishments]);

    const onRowSelected = (event: any) => {
        if (!event.node.selected) return;
        setEntityInfos(event.data);
    };

    const ActionButtonsRenderer = (props: any) => {
        const {
            data: { id, name }
        } = props;

        return (
            <div className="actions">
                {/* <button title='' onClick={() => navigateToEstablishmentDetails(data.id)} className="unstyled">
                    <IoEye className="icon" />
                </button> */}
                <button
                    className="unstyled action"
                    title="Modifier"
                    onClick={(e) => {
                        const establishment = establishments?.find((e) => e.id === id);
                        setEstablishmentToUpdate(establishment);
                        onRowSelected({
                            node: {
                                selected: true
                            },
                            data: establishment
                        });
                    }}
                >
                    <BsFillPencilFill className="icon" />
                </button>
                <button title="Archiver" className="unstyled action">
                    <FaBoxArchive
                        className="icon"
                        onClick={() =>
                            handleDeleteEstablishment({
                                id,
                                name
                            })
                        }
                    />
                </button>
            </div>
        );
    };

    const columns = [
        { headerName: 'Nom', field: 'name' },
        { headerName: 'Adresse', field: 'address' },
        { headerName: 'Contact', field: 'email' },
        { headerName: 'N° de téléphone', field: 'phone' },
        { headerName: 'Actions', cellRenderer: ActionButtonsRenderer, maxWidth: 100, minWidth: 100 }
    ];

    return (
        <div className="patients">
            <div className="title with-search">
                <h2>
                    Établissements
                    <p>{establishments?.length ?? 0} établissements enregistrés</p>
                </h2>
                <InputSearch placeholder="Recherche" onChange={handleSetSearch} />
                <Button icon={<FaPlus />} color={'blue'} text="Ajouter un établissement" onClick={() => setShowCreateEstablishment(true)} />
            </div>

            {establishments && (
                <Table
                    rows={establishments}
                    columns={columns}
                    quickFilterText={search}
                    pagination={true}
                    onRowSelected={onRowSelected}
                    paginationPageSize={DEFAULT_PAGINATION_SIZE}
                />
            )}

            {!establishments && !error && <p>Loading...</p>}

            {!establishments && error && <p className="error-message">{error}</p>}

            <Modal
                title={`Archiver un établissement`}
                open={openDeleteModal}
                onValidate={() => deleteEstablishment(establishmentToDelete)}
                isValidateDestructive={true}
                setClose={() => setOpenDeleteModal(false)}
            >
                <p>
                    Voulez-vous vraiment archiver l'établissement <b>{establishmentToDelete?.name}</b>
                </p>
            </Modal>
        </div>
    );
};
