import './Modal.scss';
import React, { ReactNode } from 'react';
import 'react-responsive-modal/styles.css';

import { Modal as ModalPkg } from 'react-responsive-modal';
import { Button } from 'components/ui-components';

export type ModalProps = {
    open: boolean;
    setClose: () => void;
    title: string;
    yesText?: string;
    noText?: string;
    onValidate?: () => void;
    isValidateDestructive: boolean;
    children?: ReactNode;
};

export const Modal: React.FC<ModalProps> = ({ open, setClose, title, yesText, noText, onValidate, isValidateDestructive, children }) => {
    return (
        <div>
            <ModalPkg open={open} onClose={setClose} center>
                <div className="modal">
                    <h2>{title}</h2>

                    <div className="body">{children}</div>

                    <div className="footer">
                        {onValidate && (
                            <Button
                                text={yesText ?? 'Oui'}
                                onClick={() => {
                                    onValidate();
                                    setClose();
                                }}
                            />
                        )}
                        <Button text={noText ?? 'Non'} color="red" onClick={setClose} />
                    </div>
                </div>
            </ModalPkg>
        </div>
    );
};
