import './AddPatient.scss';
import React from 'react';
import { useAddPatientVm } from './useAddPatientVm';
import { Wizard } from 'react-use-wizard';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { AddPatientHeader } from './AddPatientHeader';
import { AddPatientFooter } from './AddPatientFooter';
import { BookletTabsField } from 'views/authentifiedView/AuthentifiedView';
import { Patient } from 'components/Patients/Patients.types';

export type AddPatientProps = {
    bookletTabsFields: BookletTabsField[];
    createCta?: string;
    backAction: () => void;
    patientToUpdate?: Patient;
};

export const AddPatient: React.FC<AddPatientProps> = ({ bookletTabsFields, backAction, patientToUpdate, createCta }) => {
    const { handleChange, newPatient, updateBooklet, addBooklet, deleteBooklet, colorPickerToShow, setColorPickerToShow, onSubmit } =
        useAddPatientVm({
            bookletTabsFields,
            patientToUpdate,
            backAction
        });

    return (
        <div className="patient-form form">
            <Wizard
                header={<AddPatientHeader backAction={backAction} update={patientToUpdate} />}
                footer={<AddPatientFooter createCta={createCta} />}
            >
                <Step1 handleChange={handleChange} newPatient={newPatient} />
                <Step2
                    newPatient={newPatient}
                    addBooklet={addBooklet}
                    updateBooklet={updateBooklet}
                    deleteBooklet={deleteBooklet}
                    colorPickerToShow={colorPickerToShow}
                    setColorPickerToShow={setColorPickerToShow}
                    onSubmit={onSubmit}
                />
            </Wizard>
        </div>
    );
};
