import { useRoutes } from 'react-router-dom';
import './App.scss';
import { useSelector } from 'react-redux';
import { getIsLogged } from './features/auth';
import routes from './routes';

const App: React.FC = () => {
    const isLogged = useSelector(getIsLogged);
    const routing = useRoutes(routes(isLogged));

    return (
        <div className="app">
            <>{routing}</>
        </div>
    );
};

export default App;
