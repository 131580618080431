import { Button, Loader } from 'components/ui-components';
import './AddPatient.scss';
import React, { useState } from 'react';
import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnNumberedList,
    BtnUnderline,
    Editor,
    EditorProvider,
    Separator,
    Toolbar
} from 'react-simple-wysiwyg';
import { FaPlus } from 'react-icons/fa';

import { PatientToCreate } from 'components/Patients/PatientToCreate.types';
import { MdDeleteForever } from 'react-icons/md';
import { BookletTab } from 'components/Patients/Patients.types';
import OutsideAlerter from 'components/OutsideAlterter/OutsideAlerter';
import { ChromePicker } from 'react-color';
import { reformulateContent } from 'api/booklettabs';

export type Step2Props = {
    newPatient: PatientToCreate;
    colorPickerToShow: number | undefined;
    setColorPickerToShow: (i: number | undefined) => void;
    updateBooklet: (index: number, val: string, key: string) => void;
    addBooklet: () => void;
    deleteBooklet: (index: number) => void;
    onSubmit: any;
};

export const Step2: React.FC<Step2Props> = ({
    newPatient,
    updateBooklet,
    addBooklet,
    deleteBooklet,
    colorPickerToShow,
    setColorPickerToShow,
    onSubmit
}) => {
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedBooklet, setSelectedBooklet] = useState<number>(0);

    const checkFields = () => newPatient.booklets.reduce((acc: boolean, b: BookletTab) => acc && b.title != '' && b.content != '', true);

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
    };

    const aiReformulate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, bookletId: number) => {
        e.preventDefault();
        const content = newPatient.booklets[bookletId].content;
        if (!content || content.length < 20) return;
        setLoading(true);
        reformulateContent(content).then((data) => {
            updateBooklet(bookletId, data.data.message, 'content');
            setLoading(false);
        });
    };

    return (
        <div className="step-two step">
            <Button
                text="Ajouter un onglet"
                icon={<FaPlus />}
                className="ml-auto"
                onClick={(e) => {
                    e.preventDefault();
                    addBooklet();
                }}
            />

            <div className="booklet-tiles">
                {newPatient.booklets &&
                    newPatient.booklets.length &&
                    newPatient.booklets.map((booklet: any, i: number) => (
                        <div
                            className={
                                'booklet-tile' +
                                (newPatient.booklets[i].content !== '' && newPatient.booklets[i].title !== '' ? ' checked' : '') +
                                (selectedBooklet === i ? ' selected' : '')
                            }
                            onClick={() => {
                                setSelectedBooklet(i);
                            }}
                        >
                            {booklet?.title ?? 'Une erreur est survenue'}
                        </div>
                    ))}
            </div>

            <form
                id="form-patient-dico"
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setError(false);
                    if (checkFields()) {
                        onSubmit();
                    } else {
                        setError(true);
                    }
                }}
            >
                {newPatient.booklets && newPatient.booklets.length && (
                    <div className="wysiwyg" key={`generated-wysiwyg-${selectedBooklet}`}>
                        {selectedBooklet < 8 && (
                            <div className="title-step-wrapper">
                                <div className="title-step">
                                    {newPatient.booklets[selectedBooklet].title}
                                    <div
                                        className="color-preview"
                                        style={{ backgroundColor: newPatient.booklets[selectedBooklet].color, cursor: 'not-allowed' }}
                                    ></div>
                                </div>
                                <Button
                                    className="reformulate-button"
                                    text="Reformuler avec l'IA"
                                    color="yellow"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        e.preventDefault();
                                        aiReformulate(e, selectedBooklet);
                                    }}
                                ></Button>
                            </div>
                        )}

                        {selectedBooklet > 7 && (
                            <>
                                <div className="title-step-wrapper">
                                    <div className="title-step">
                                        {newPatient.booklets[selectedBooklet].title
                                            ? newPatient.booklets[selectedBooklet].title
                                            : "Titre de l'onglet"}
                                        <div
                                            className="color-preview"
                                            style={{ backgroundColor: newPatient.booklets[selectedBooklet].color }}
                                            onClick={() => selectedBooklet > 7 && setColorPickerToShow(selectedBooklet)}
                                        ></div>
                                        {selectedBooklet === colorPickerToShow && (
                                            <div className="color-picker-wrapper abs">
                                                <OutsideAlerter callback={() => setColorPickerToShow(undefined)}>
                                                    <ChromePicker
                                                        className="abs"
                                                        color={newPatient.booklets[selectedBooklet].color}
                                                        onChange={(color) => updateBooklet(selectedBooklet, color.hex, 'color')}
                                                    />
                                                </OutsideAlerter>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Button
                                    text="Supprimer l'onglet"
                                    icon={<MdDeleteForever />}
                                    color="red"
                                    className="abs-del"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (selectedBooklet === newPatient.booklets.length - 1) {
                                            setSelectedBooklet((prevSelectedBooklet) => --prevSelectedBooklet);
                                        }
                                        deleteBooklet(selectedBooklet);
                                    }}
                                />
                                <div className="input-wrapper">
                                    <input
                                        className="input"
                                        required
                                        placeholder="Titre de l'onglet"
                                        value={newPatient.booklets[selectedBooklet].title}
                                        onChange={(e) => updateBooklet(selectedBooklet, e.target.value, 'title')}
                                    ></input>
                                    <Button
                                        className="reformulate-button"
                                        text="Reformuler avec l'IA"
                                        color="yellow"
                                        onClick={(e) => aiReformulate(e, selectedBooklet)}
                                    ></Button>
                                </div>
                            </>
                        )}

                        <EditorProvider>
                            <Editor
                                value={newPatient.booklets[selectedBooklet].content as string}
                                onChange={(e) => updateBooklet(selectedBooklet, e.target.value, 'content')}
                                onPaste={handlePaste}
                            >
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnUnderline />
                                    <Separator />
                                    <BtnBulletList />
                                    <BtnNumberedList />
                                </Toolbar>
                            </Editor>
                            {loading && <Loader />}
                        </EditorProvider>
                    </div>
                )}
                {error && <p className="form-error">Veuillez remplir la totalité des onglets du dico perso</p>}
            </form>
        </div>
    );
};
