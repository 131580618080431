import { Header } from 'components/index';
import { Outlet } from 'react-router-dom';

const UnAuthentifiedLayout = () => (
    <>
        <Header />
        <Outlet />
    </>
);

export default UnAuthentifiedLayout;
